<template>
  <div class="orderMaterialQuery">
    <PageHeader title="订单物料查询" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex">
        <el-popover
          class="OrderDropDown"
          placement="bottom"
          trigger="hover"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="cursor" @click="selectType(1)">按订单查询</div>
            <div class="p-tb-10 cursor" @click="selectType(2)">按交货单查询</div>
          </div>
          <div slot="reference" class="out white bg p-lr-10 p-tb-5 min-border-right flex a-center f12 m-right-10 pointer" @click="visible = !visible">
            <div>{{ formSearch.ReportType === 2 ? '按交货单查询' : '按订单查询' }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
      </div>
      <div class="flex m-left-10 SearchBox_30  m-right-20" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          :placeholder=" ReportType === 1 ? '可通过订单编号、物料号、物料名称、终端/买方、供应商名称搜索' : '可通过订单编号、交货单号、物料号、物料名称、终端/买方搜索'"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer" @click="search">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            class="cursor"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <div v-if=" formSearch.ReportType === 1" class="flex">
        <el-popover
          v-model="visible1"
          class="OrderDropDown"
          placement="bottom"
          trigger="manual"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="cursor" @click="selectType1(1)">已发清</div>
            <div class="p-tb-10 cursor" @click="selectType1(2)">未发清</div>
          </div>
          <div slot="reference" class="out white bg p-lr-10 p-tb-5 min-border-right flex a-center f12 m-right-10 pointer" @click="visible1 = !visible1">
            <div>{{ formSearch.IsOver === 2 ? '未发清' : '已发清' }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
      </div>
      <div
        v-if="$minCommon.checkMeau('ddwlcxexport', userBottons)"
        slot="reference"
        class="out p-lr-10 min-border flex a-center m-right-10 pointer ModelBttton-white-30"
        @click="exportS "
      >
        <i class="el-icon-upload2 f12" /> 导出
      </div>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchForm,'WriteTime','CreateStartTime','CreateEndTime')" />
    <div class="p-lr-20">
      <!-- 按订单查询 -->
      <el-table
        v-if="formSearch.ReportType === 1"
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 330px)"
        row-key="id"
        border
        :fit="true"
        @select-all="selectAll"
        @select="select"
      >
        <!--        :max-height="$minCommon.SetTableHeight(330)"
-->
        <el-table-column :key="Math.random()* 10 " fixed="left" type="selection" width="40" />
        <el-table-column prop="OrderID" label="订单编号" show-overflow-tooltip />
        <el-table-column prop="BuyCompanyName" label="买方" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.BuyCompanyName ? scope.row.BuyCompanyName : scope.row.SendCompanyName }}
          </template>
        </el-table-column>
        <el-table-column prop="SendCompanyName" label="终端" show-overflow-tooltip />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="MaterialTypeName" label="物料类型" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column prop="StockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column prop="MaterialStorageConditionName" label="存储条件" show-overflow-tooltip />
        <el-table-column prop="Num" label="订购数量" show-overflow-tooltip />
        <el-table-column prop="NumSend" label="已发数量" show-overflow-tooltip />
        <el-table-column prop="NumLeft" label="未清数量" show-overflow-tooltip />
        <el-table-column prop="CreateUserName" label="创建人" show-overflow-tooltip />
        <el-table-column prop="WriteTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>

      </el-table>
      <!-- 按交货单查询 -->
      <el-table
        v-if="formSearch.ReportType === 2"
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        :height="tableHeight"
        row-key="id"
        border
        :fit="true"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column :key="Math.random()*10" fixed="left" type="selection" width="40" />
        <el-table-column prop="OrderID" label="订单单号" show-overflow-tooltip />
        <el-table-column prop="SapDeliveryID" label="交货单号" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.SapDeliveryID }}
          </template>
        </el-table-column>
        <el-table-column prop="BuyCompanyName" label="买方" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.BuyCompanyName ? scope.row.BuyCompanyName : scope.row.SendCompanyName }}
          </template>
        </el-table-column>
        <el-table-column prop="SendCompanyName" label="终端" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column prop="Num" label="数量" show-overflow-tooltip />
        <!-- <el-table-column prop="StockUnitN" label="单位" show-overflow-tooltip /> -->
        <el-table-column prop="LotNum" label="批号（流水号）" show-overflow-tooltip />
        <el-table-column prop="ExpiredDateFormat" label="效期" show-overflow-tooltip />
        <el-table-column prop="WriteTime" label="创建时间" show-overflow-tooltip />
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <Adsearch
      :show="isShow"
      :form="formSearch"
      :ad-search-form="adSearchForm"
      @cancel="(e) => (isShow = e)"
      @searchAd="searchAd"
    />
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
  </div>
</template>
<script>
import Index from '@/minxin/orderMananger/orderMaterialQuery'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>
