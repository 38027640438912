import PageHeader from '@/components/PageHeader.vue'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'
const Index = {
  name: 'orderMaterialQuery',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin, root],
  data() {
    return {
      root_name: 'ddwlcx',
      root_msg: '订单物料查询',
      visible: false,
      visible1: false,
      formSearch: {
        ReportType: 1, // 1： 按订单 2：按交货单
        OrderID: '',
        SendCompanyName: '',
        BuyCompanyName: '',
        InvName: '',
        InvCode: '',
        ManuPartNum: '',
        CreateStartTime: '',
        CreateEndTime: '',
        CreateUserId: '',
        WriteTime: [],
        DeliveryID: '',
        OrderType: 1, // 订单类型 1 ：销售订单 2 ：采购订单
        BrandName: '',
        IsOver: 2, // 2. 未清  1. 已清
        KeyWords: '',
        SelectIDList: [],
        PageIndex: 1,
        PageSize: 10
      },
      adSearchForm: [
        { name: '订单编号', value: 'OrderID' },
        { name: '终端', value: 'SendCompanyName' },
        { name: '买方', value: 'BuyCompanyName' },
        { name: '物料名称', value: 'InvName' },
        { name: '品牌', value: 'BrandName' },
        { name: '物料编号', value: 'InvCode' },
        { name: '原厂编号', value: 'ManuPartNum' },
        { name: '创建人', value: 'NickName' },
        { name: '创建时间', value: 'WriteTime', date: true }
      ],
      isShow: false,
      selects: []
    }
  },
  watch: {
    selects(a) {
      this.formSearch.SelectIDList = []
      if (a.length <= 0) return this.formSearch.SelectIDList = []
      a.map(item => {
        this.formSearch.SelectIDList.push(item.IDX)
      })
    }
  },
  methods: {
    searchAd() {
      if (this.formSearch.WriteTime.length > 0) {
        this.formSearch.CreateStartTime = this.formSearch.WriteTime[0]
        this.formSearch.CreateEndTime = this.formSearch.WriteTime[1]
      }
      console.log(this.formSearch)
      this.setKeysData(this.formSearch, this.adSearchForm) // 展示搜索条件
      this.getTableDataAsync()
      this.currentPage = 1
      this.isShow = false
    },
    search() {
      this.isShow = true
    },
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    selectType(type) {
      this.formSearch.ReportType = type
      this.visible = false
      this.currentPage = 1
      this.getTableDataAsync()
    },
    selectType1(type) {
      this.formSearch.IsOver = type
      this.visible1 = false
      this.currentPage = 1
      this.getTableDataAsync()
    },
    exportS() {
      // api/ExportGetOrderInfoNotFinshList this.formSearch
      this.$refs.ExportLoading.open()
      this.$api.ExportGetOrderInfoNotFinshList(this.formSearch).then(res => {
        if (res.RetCode === '0') {
          const name = this.$minCommon.getExportName('订单查询')
          this.$refs.ExportLoading.success(res.Message, name)
          return this.$minCommon.downloadFile(res.Message, name)
        }
        return this.$message.error('错误：', res.Msg)
      })
    },
    // 按物料
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.GetOrderInfoNotFinshList(this.formSearch)
      this.tableData = []
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      console.log(response)
      return response
    }
  }
}
export default Index
